import {
    useLocation, matchPath
} from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import routes from '../setup/routes';

export default function useIsPrivateRoute() {
    const location = useLocation();

    const isPrivateRoute = routes.some((routeConfig) => {
        const match = matchPath(routeConfig, location.pathname);
        return isEmpty(match) && routeConfig.isPrivate;
    });
    const isRouteExist = routes.some((routeConfig) => matchPath(routeConfig, location.pathname));
    return {isPrivateRoute, isRouteExist};
}
