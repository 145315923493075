import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import classnames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import {HiOutlineMenu} from '@react-icons/all-files/hi/HiOutlineMenu';
import {HiOutlineX} from '@react-icons/all-files/hi/HiOutlineX';
import {FaHamburger} from '@react-icons/all-files/fa/FaHamburger';
import get from 'lodash.get';
import Logo from '../Logo/Logo';
import LanguageSelector from './LanguageSelector/LanguageSelector';
import ThemeSelector from './ThemeSelector/ThemeSelector';
import UserMenu from './UserMenu/UserMenu';
import useIsPrivateRoute from '../../../hooks/useIsPrivateRoute';
import {
    selectFeatures, selectHasUserPermissionOnRoot, selectPermissions, selectUser
} from '../../Login/userSlice';
import useTransactionsPageNavigate from '../../../hooks/useTransactionsPageNavigate';
import useTemplatesPageNavigate from '../../../hooks/useTemplatesPageNavigate';
import Delegation from '../../Delegation/Delegation';
import Subaccounts from './Subaccounts/Subaccounts';
import permissionsService from '../../../utils/permissionsService';
import {
    DASHBOARD_PATH,
    REPORTS_PATH,
    TEMPLATES_PATH,
    TEMPLATE_PATH,
    TRANSACTIONS_PATH,
    PACKAGE_QUERY_OPTIONS,
    TRANSACTION_PATH
} from '../../../../constants';
import './header.less';

export default function Header() {
    const user = useSelector(selectUser);
    const location = useLocation();
    const isFatIcons = get(user, 'data.fatIcons');
    const intl = useIntl();
    const {isPrivateRoute, isRouteExist} = useIsPrivateRoute();
    const features = useSelector(selectFeatures);
    const permissions = useSelector(selectPermissions);
    const hasUserPermissionOnRoot = useSelector(selectHasUserPermissionOnRoot);
    const [isNavOpen, setIsNavOpen] = useState(false);
    const className = classnames('header', {
        'show-items': isPrivateRoute || !isRouteExist
    });
    const navigateToTransactionsWithParams = useTransactionsPageNavigate(null, true);
    const navigateToTemplatesWithParams = useTemplatesPageNavigate(null, true);

    function openNav() {
        setIsNavOpen(true);
    }

    function closeNav() {
        setIsNavOpen(false);
    }

    function navigateToTransactions(e) {
        e.preventDefault();
        navigateToTransactionsWithParams({
            query: PACKAGE_QUERY_OPTIONS.INBOX
        }, true);
        closeNav();
    }

    function navigateToTemplates(e) {
        e.preventDefault();
        navigateToTemplatesWithParams({}, true);
        closeNav();
    }

    function getIsActiveClass(routes = []) {
        const isActive = routes.some((route) => location.pathname.startsWith(route));
        return isActive ? 'is-active' : '';
    }

    const links = (
        <>
            <div className={`dashboard left link-container ${getIsActiveClass([DASHBOARD_PATH])}`}>
                <Link
                    to={DASHBOARD_PATH}
                    className="link"
                    onClick={closeNav}
                    data-wdio="test-header-go-to-dashboard-btn"
                >
                    {intl.formatMessage({id: 'esl.generic.dashboard', defaultMessage: ''})}
                </Link>
            </div>
            {permissionsService.hasTransactionPermission({features, permissions, hasUserPermissionOnRoot}) && (
                <div className={`transactions left link-container wh-transactions-link ${getIsActiveClass([TRANSACTIONS_PATH, TRANSACTION_PATH])}`}>
                    <a
                        className="link"
                        onClick={navigateToTransactions}
                        href={TRANSACTIONS_PATH}
                        data-wdio="test-header-go-to-transactions-btn"
                    >
                        {intl.formatMessage({id: 'esl.generic.transactions', defaultMessage: ''})}
                    </a>
                </div>
            )}
            {permissionsService.hasTransactionPermission({features, permissions, hasUserPermissionOnRoot}) && (
                <div className={`templates left link-container ${getIsActiveClass([TEMPLATES_PATH, TEMPLATE_PATH])}`}>
                    <a
                        className="link"
                        onClick={navigateToTemplates}
                        href={TEMPLATES_PATH}
                        data-wdio="test-header-go-to-templates-btn"
                    >
                        {intl.formatMessage({id: 'esl.generic.templates', defaultMessage: ''})}
                    </a>
                </div>
            )}
            {permissionsService.hasReportPagePermission({features, permissions, hasUserPermissionOnRoot}) && (
                <div className={`reports left link-container ${getIsActiveClass([REPORTS_PATH])}`}>
                    <Link
                        to={REPORTS_PATH}
                        className="link"
                        onClick={closeNav}
                        data-wdio="test-header-go-to-reports-btn"
                    >
                        {intl.formatMessage({id: 'esl.generic.reports', defaultMessage: ''})}
                    </Link>
                </div>
            )}
        </>
    );

    return (
        <div className="header-container">
            <div className={className}>
                <div className="navigation-icon-container">
                    <IconButton
                        edge="end"
                        onClick={openNav}
                        color="inherit"
                        aria-label={intl.formatMessage({id: 'page.header.menu', defaultMessage: ''})}
                        data-wdio="test-header-navigation-btn"
                    >
                        {!isFatIcons ? <HiOutlineMenu /> : <FaHamburger />}
                    </IconButton>
                </div>
                <Drawer
                    anchor="left"
                    open={isNavOpen}
                    classes={{root: 'left-navigation'}}
                    onClose={closeNav}
                    data-wdio="test-header-navigation-panel-container"
                >
                    <div className="close-icon-container">
                        <IconButton
                            edge="end"
                            onClick={closeNav}
                            color="inherit"
                        >
                            <HiOutlineX />
                        </IconButton>
                    </div>
                    <div className="menu-title">
                        {intl.formatMessage({id: 'page.header.menu', defaultMessage: ''})}
                    </div>
                    {links}
                </Drawer>
                <Logo />
                {links}
                <div className="right-container">
                    <Delegation />
                    <LanguageSelector shouldShowIcon />
                    <ThemeSelector shouldShowIcon />
                    <Subaccounts shouldShowIcon />
                    <UserMenu />
                </div>
            </div>
        </div>
    );
}
