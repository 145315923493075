import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import {HiOutlineUserGroup} from '@react-icons/all-files/hi/HiOutlineUserGroup';
import {TiGroup} from '@react-icons/all-files/ti/TiGroup';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import classnames from 'classnames';
import stringSimilarity from 'string-similarity';
import {
    fetchUsers,
    selectUsers,
    delegateUser,
    stopDelegation
} from './delegationSlice';
import {selectDelegationUser, selectUser} from '../Login/userSlice';
import {BROADCAST_MESSAGES} from '../../../constants';
import Search from '../shared/Search/Search';
import useBroadcaster from '../../hooks/useBroadcaster';
import './delegation.less';

export default function Delegation() {
    const intl = useIntl();
    const dispatch = useDispatch();
    const broadcast = useBroadcaster();
    const userData = useSelector(selectUser);
    const users = useSelector(selectUsers);
    const delegatedUser = useSelector(selectDelegationUser);
    const isFatIcons = get(userData, 'data.fatIcons');
    const defaultUser = !isEmpty(delegatedUser) ? delegatedUser.id : '';
    const [user, setUser] = useState(defaultUser);
    const [search, setSearch] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isDelegated = !isEmpty(delegatedUser);
    const searchedUsers = !isEmpty(users) ? users.filter(({name = '', email = ''}) => {
        const isNameMatch = stringSimilarity
            .compareTwoStrings(name.toLowerCase(), search.toLowerCase());
        const isEmailMatch = stringSimilarity
            .compareTwoStrings(email.toLowerCase(), search.toLowerCase());
        return search === '' || search.length < 3 || isNameMatch >= 0.3 || isEmailMatch >= 0.3;
    }) : [];
    const iconClassName = classnames('delegation-icon', {
        'is-delegated': isDelegated
    });

    const changeUser = (event) => {
        setUser(event.target.value);
    };

    useEffect(() => {
        dispatch(fetchUsers());
    }, []);

    function open() {
        setIsModalOpen(true);
    }

    function close() {
        setSearch('');
        setIsModalOpen(false);
    }

    async function delegate() {
        const wasSuccessful = await dispatch(delegateUser(user));
        if (wasSuccessful) {
            close();
            broadcast(BROADCAST_MESSAGES.SUBACCAUNT_CHANGED);
        }
    }

    async function stopUserDelegation() {
        const wasSuccessful = await dispatch(stopDelegation());
        if (wasSuccessful) {
            setUser('');
            close();
            broadcast(BROADCAST_MESSAGES.SUBACCAUNT_CHANGED);
        }
    }

    function changeSearch(value) {
        setSearch(value);
    }

    if (isEmpty(users)) {
        return null;
    }

    function renderModal() {
        return (
            <Dialog
                maxWidth="md"
                open={isModalOpen}
                classes={{root: 'delegation-modal'}}
                onClose={close}
            >
                <DialogTitle>
                    {intl.formatMessage({id: 'esl.generic.manage_delegation', defaultMessage: ''})}
                </DialogTitle>
                <DialogContent>
                    {intl.formatMessage({id: 'esl.generic.manage_delegation_description', defaultMessage: ''})}
                    <Search onChange={changeSearch} />
                    <div className="users-container">
                        <RadioGroup aria-label="delegation users" name="users" value={user} onChange={changeUser}>
                            {searchedUsers.map(({id, email, name}) => (
                                <FormControlLabel
                                    value={id}
                                    control={<Radio size="small" />}
                                    key={`deledated-user-${id}`}
                                    label={(
                                        <div className="user">
                                            <div className="user-name">{name}</div>
                                            <div className="user-email">{email}</div>
                                        </div>
                                    )}
                                />
                            ))}
                        </RadioGroup>
                    </div>
                    {isEmpty(searchedUsers) && search !== '' && (
                        <div className="no-result-container">
                            <div className="no-result">
                                {intl.formatMessage({id: 'esl.feedback.sender_not_found', defaultMessage: ''})}
                            </div>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} classes={{root: 'cancel-btn'}}>
                        {intl.formatMessage({id: 'esl.generic.cancel', defaultMessage: ''})}
                    </Button>
                    {isDelegated && delegatedUser.id === user ? (
                        <Button
                            onClick={stopUserDelegation}
                            classes={{root: 'stop-delegation-btn'}}
                            color="primary"
                        >
                            {intl.formatMessage({id: 'esl.generic.stop_managing', defaultMessage: ''})}
                        </Button>
                    ) : (
                        <Button
                            onClick={delegate}
                            classes={{root: 'delegate-btn'}}
                            color="primary"
                            disabled={user === ''}
                        >
                            {intl.formatMessage({id: 'esl.generic.start_managing', defaultMessage: ''})}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        );
    }

    const button = (
        <IconButton
            edge="end"
            onClick={open}
            color="inherit"
            classes={{root: 'delegation-icon-btn'}}
        >
            {!isFatIcons
                ? <HiOutlineUserGroup className={iconClassName} />
                : <TiGroup className={iconClassName} />}
        </IconButton>
    );

    return (
        <div className="delegation-icon-container">
            {isDelegated
                ? (
                    <Tooltip
                        title={intl.formatMessage({id: 'esl.generic.manage_transactions_for', defaultMessage: ''}, {
                            name: delegatedUser.name
                        })}
                        arrow
                    >
                        {button}
                    </Tooltip>
                )
                : button}
            {renderModal()}
        </div>
    );
}
