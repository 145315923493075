import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import isEmpty from 'lodash.isempty';
import deburr from 'lodash.deburr';
import Menu from '@material-ui/core/Menu';
import Search from '../../../shared/Search/Search';
import {
    selectActiveAccountId, selectFeatures, selectHasUserPermissionOnRoot
} from '../../../Login/userSlice';
import Hierarchy from '../../../shared/icons/Hierarchy';
import useJoinMessage from '../../../../hooks/useJoinMessage';
import {
    changeSubaccountId,
    fetchAccessibleSubaccounts,
    fetchSubaccounts,
    selectAccessibleAccounts,
    selectSubaccounts
} from '../../../AccountSettings/accountSettingsSlice';
import useBroadcaster from '../../../../hooks/useBroadcaster';
import useNotifications from '../../../../hooks/useNotifications';
import {BROADCAST_MESSAGES} from '../../../../../constants';
import './subaccounts.less';

function getSuggestions(value, subaccounts = []) {
    const inputValue = deburr(value.trim())?.toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0 ? subaccounts : subaccounts.filter((item) => item.accountName.toLowerCase().includes(inputValue));
}

export default function Subaccounts() {
    const intl = useIntl();
    const {showSuccessAlert} = useNotifications();
    const joinMessage = useJoinMessage();
    const dispatch = useDispatch();
    const broadcast = useBroadcaster();
    const activeAccountId = useSelector(selectActiveAccountId);
    const hasUserPermissionOnRoot = useSelector(selectHasUserPermissionOnRoot);
    const features = useSelector(selectFeatures);
    const allSubaccounts = useSelector(selectSubaccounts);
    const accessibleSubaccounts = useSelector(selectAccessibleAccounts);
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const isMenuOpen = !!anchorEl;
    const menuId = 'subaccounts-menu';
    const subaccounts = hasUserPermissionOnRoot ? allSubaccounts.map(({name, id}) => ({
        accountName: name,
        accountUid: id
    })) : accessibleSubaccounts;
    const suggesstion = getSuggestions(searchValue, subaccounts);
    const activeAccountName = subaccounts.find(({accountUid}) => activeAccountId === accountUid)?.accountName;

    function handleMenuOpen(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    async function changeSubaccount(option) {
        if (option.accountUid !== activeAccountId) {
            const wasSuccessful = await dispatch(changeSubaccountId(option.accountUid));
            if (wasSuccessful) {
                handleMenuClose();
                showSuccessAlert(intl.formatMessage({id: 'esl.generic.saved', defaultMessage: ''}));
                broadcast(BROADCAST_MESSAGES.SUBACCAUNT_CHANGED);
            }
        }
    }

    useEffect(() => {
        if (hasUserPermissionOnRoot === true) {
            if (isEmpty(allSubaccounts)) {
                dispatch(fetchSubaccounts());
            }
        } else if (isEmpty(accessibleSubaccounts)) {
            dispatch(fetchAccessibleSubaccounts());
        }
    }, []);

    function renderMenu() {
        return (
            <Menu
                id={menuId}
                open={isMenuOpen}
                anchorEl={anchorEl}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                onClose={handleMenuClose}
                MenuListProps={{
                    disablePadding: true,
                    classes: {root: 'subaccounts-list'}
                }}
                keepMounted
            >
                {subaccounts.length > 5 && (
                    <Search
                        setSearchValue={setSearchValue}
                        placeholderText={`${joinMessage('esl.generic.search')} ${joinMessage('page.account.subbaccount').toLowerCase()}`}
                        forText={joinMessage('page.account.subbaccount')}
                        searchOption={searchValue}
                    />
                )}
                <div className="options-wrapper">
                    {suggesstion.map((option) => (
                        <MenuItem
                            onClick={() => changeSubaccount(option)}
                            key={option.accountUid}
                            selected={activeAccountId === option.accountUid}
                        >
                            {option.accountName}
                        </MenuItem>
                    ))}
                </div>
            </Menu>
        );
    }

    if (isEmpty(subaccounts) || !features.subAccounts) {
        return null;
    }

    return (
        <div className="subaccounts-selector">
            <Tooltip
                title={(
                    <>
                        {joinMessage('esl.generic.select')}
                        {' '}
                        {joinMessage('page.account.subbaccount').toLowerCase()}
                        {activeAccountName && (
                            <>
                                <br />
                                {joinMessage('esl.conditionals.states.selected')}
                                {' '}
                                <b>{activeAccountName}</b>
                            </>
                        )}
                    </>
                )}
                arrow
            >
                <IconButton
                    edge="end"
                    aria-label={joinMessage('esl.generic.select', 'page.account.subbaccount')}
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleMenuOpen}
                    color="inherit"
                >
                    <Hierarchy aria-hidden />
                </IconButton>
            </Tooltip>
            {renderMenu()}
        </div>
    );
}
